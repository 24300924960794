// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$expat-angular-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$expat-angular-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$expat-angular-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$expat-angular-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $expat-angular-primary,
      accent: $expat-angular-accent,
      warn: $expat-angular-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($expat-angular-theme);
@import url('https://use.typekit.net/dzx0ohe.css');
@import './shared-styles/global-variables';
@import './shared-styles/shared-items';

.expat-mat-select-box {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      background-color: #ffffff;
      border: 1px solid #b7b7b7;
      border-radius: 3px;
    }

    .mat-form-field-underline {
      display: none;
    }
  }

  .mat-select-arrow {
    border: none;
    background-image: url('https://images.ctfassets.net/huwbudysxepr/1cq3uGwoS2idFNejg1GKD2/514282c4118043201c0897585957456b/blue-arrow-down.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 20px;
    height: 13px;
  }
}

.expat-mat-checkbox {
  &.full-width {
    display: block;
    width: 100%;
  }

  &.mat-checkbox-checked {
    &.mat-accent {
      .mat-checkbox-background {
        background-color: $blue;
      }
    }

    .mat-checkbox-ripple {
      .mat-ripple-element {
        background-color: $blue;
      }
    }
  }
}

.expat-custom-tooltip {
  position: relative;
  cursor: pointer;
  display: inline;

  &:hover {
    .expat-custom-tooltip-content {
      display: block;
    }
  }

  .expat-custom-tooltip-trigger {
    background-color: $blue;
    width: 16px;
    height: 16px;
    display: inline-block;
    line-height: 1rem;
    text-align: center;
    border-radius: 50%;
    color: #ffffff;
    font-size: 0.75rem;
  }

  .expat-custom-tooltip-content {
    display: none;
    position: absolute;
    background-color: #fcfcfc;
    border: 1px solid #ddd;
    width: 200px;
    padding: 5px 8px;
    border-radius: 3px;
    overflow: visible;
    top: -85px;
    right: -10px;
    box-shadow: 1px -1px 16px -1px #ddd;

    &::after {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fcfcfc;
      text-align: center;
      position: absolute;
      bottom: -9px;
      transform: rotate(45deg);
      right: 10px;
    }
  }
}

html,
body {
  height: 100%;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: $fontFamilyRegular;
  font-size: 0.875rem;
  line-height: 1.313rem;
  background-color: #f1f1f1;
  color: #707070;
}

//Fix for auto filled form fields having background color
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.blue {
  color: $blue;
}

.info-tooltip-custom {
  border-radius: 50%;
  font-size: 0.625rem;
  padding: 3px;
  border: 0;
  color: #ffffff;
  background-color: $blue;
  width: 15px;
  height: 15px;
  line-height: 0.188rem;
}

.swal-close-custom {
  &.swal2-close {
    outline: none;
    background-color: $red;
    border-radius: 0 3px 0 0;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;

    &.swal2-close:hover {
      transform: none;
      background: $red;
      color: #f27474;
      outline: none;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }
}

.swal2-container {
  &.throw-error-generic {
    .throw-error-generic-button {
      min-width: 130px;
    }

    ul {
      text-align: left;
    }
  }
}

.white-bg {
  background-color: #ffffff;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $fontFamilyHeadings;
  color: $red;
  font-weight: 700;
}

.green-cta {
  background: transparent linear-gradient(180deg, #5cb85c 0%, #53aa53 100%) 0 0 no-repeat padding-box;
  box-shadow: 0 3px 15px #74b5664d;
  border: 1px solid #3c773d;
  border-radius: 3px;
  color: #ffffff;
  text-align: center;
  width: 100%;
  padding: 15px;
  display: block;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.375rem;

  &:hover {
    background: transparent linear-gradient(180deg, #53aa53 0%, #5cb85c 100%) 0 0 no-repeat padding-box;
  }
}

.blue-cta {
  background: transparent linear-gradient(180deg, #0986c8 0%, #097ebc 100%) 0 0 no-repeat padding-box;
  transition: all 0.2s ease;
  box-shadow: 0 3px 15px #3985c233;
  color: #ffffff;
  border: 1px solid #056598;
  border-radius: 3px;
  padding: 10px 40px;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    background: transparent linear-gradient(180deg, #097ebc 0, #0986c8 100%) 0 0 no-repeat padding-box;
    color: #ffffff;
  }
}

.yellow-cta {
  color: #000000;
  box-shadow: 0 3px 15px #74b5664d;
  border: 1px solid #e9db29;
  border-radius: 3px;
  background: #fff349;
  padding: 10px 15px;
  margin-top: 15px;
  display: block;
  font-weight: 700;
  line-height: 1.375rem;
  text-align: center;
  cursor: pointer;
  width: 100%;
  background: linear-gradient(180deg, #fff349 0, #f3e741);

  &:hover {
    background: linear-gradient(180deg, #f3e741 0, #fff349);
  }
}

h2 {
  &.red-fade {
    position: relative;
    font-family: $fontFamilyHeadings;
    font-size: 1.75rem;
    font-weight: bold;
    color: $red;
    margin: 0 0 20px;

    &::after {
      content: '';
      display: block;
      margin-top: 10px;
      height: 1px;
      width: 100%;
      background: linear-gradient(to right, $red 0%, rgba($red, 0.05) 100%);
    }

    .blue-link {
      font-family: $fontFamilyRegular;
      font-size: 1rem;
      font-weight: normal;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      line-height: 1rem;
      transition: opacity 0.5s ease;
      @media screen and (max-width: $break-medium) {
        display: none;
      }
    }

    @media screen and (max-width: $break-medium) {
      font-size: 1.5rem;
      text-align: center;

      &::after {
        background: linear-gradient(to right, rgba($red, 0.05) 0%, $red 50%, rgba($red, 0.05) 100%);
      }
    }
  }
}

a {
  color: $red;
  text-decoration: none;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $red;
    opacity: 0.6;
  }
}

h3 {
  font-size: 1.75rem;
  line-height: 2.25rem;
}

h5 {
  font-size: 1.125rem;
  line-height: 1.313rem;
}

.red {
  color: $red;
}

.red-fade {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    bottom: 0;
    background: linear-gradient(90deg, #bc2224 0, rgba(188, 34, 36, 0));
  }
}

.yellow-fade {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    bottom: 0;
    background: linear-gradient(90deg, $yellow 0, rgba(188, 34, 36, 0));
  }
}

.fade-red-fade {
  &::after {
    content: '';
    margin-top: 10px;
    display: block;
    height: 1px;
    width: 100%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(188, 34, 36, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }
}

.dropdown-box {
  position: relative;

  &.open {
    .box {
      display: block;
    }
  }

  .box {
    position: absolute;
    display: none;
  }

  .box-opener {
    cursor: pointer;
    user-select: none;
  }
}

.controls-next-prev {
  text-align: center;

  .controls-inner {
    user-select: none;
    display: inline;

    > div {
      display: inline;
    }

    .swiper-btn {
      outline: none;
    }
  }

  .swiper-pagination-fraction {
    color: #bc2224;
    width: auto;
  }

  .swiper-pagination-bullet {
    border: 1px solid #bc2224;
    background-color: #ffffff;
    opacity: 1;
    outline: none;
    width: 12px;
    height: 12px;
    margin-right: 20px;
    border-radius: 50%;
    display: inline-block;

    &:last-child {
      margin-right: 0;
    }
  }

  .swiper-pagination-bullet-active {
    background: #bc2224;
  }

  .next,
  .prev {
    background-repeat: no-repeat;
    background-size: 9px 15px;
    cursor: pointer;

    span {
      font-size: 1rem;
      text-decoration: underline;
      color: #bc2224;
      line-height: 1.5rem;
    }
  }

  .prev {
    background-image: url('https://images.ctfassets.net/zggpk8714k6l/4sexzloTKKOPPg1oAfd7R8/f49cfa6b8aa33964d365811503ee99a4/Arrow-left-red.svg');
    padding-left: 20px;
    background-position: 0 5px;
    margin-right: 20px;
  }

  .next {
    background-image: url('https://images.ctfassets.net/zggpk8714k6l/3wnPfFsoNpTy9L9fKdRyzW/7d415dfaddda78511660add1086fdc51/Arrow-right-red.svg');
    padding-right: 20px;
    background-position: 100% 5px;
    margin-left: 20px;
  }
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: $red;
}

.mat-accordion {
  .mat-expansion-panel {
    margin: 16px 0;
    background-color: #f7f7f7;

    .mat-expansion-panel-header {
      background-color: #f7f7f7;
      min-height: 50px;
      height: auto;

      * {
        user-select: none;
      }

      .mat-expansion-panel-header-title {
        color: $darkGrey;
        font-weight: 700;
        padding: 15px 35px;
        font-size: 1.125rem;

        &::before {
          content: 'Q.';
          position: absolute;
          left: 25px;
          color: $red;
        }
      }

      &:hover {
        background-color: #f7f7f7;
      }
    }

    .mat-expansion-panel-body,
    .mat-expansion-panel-header-description {
      padding: 15px 15px 15px 30px;
      background-color: #ffffff;
      color: $text;
    }

    .mat-expansion-indicator {
      &::after {
        padding: 7px;
        vertical-align: super;
        border-width: 0 3px 3px 0;
      }
    }

    .mat-expansion-panel-header-description {
      position: relative;

      &::before {
        content: 'A.';
        position: absolute;
        top: 10px;
        left: -10px;
        color: #bc2224;
        font-weight: 700;
        font-size: 1.125rem;
        padding: 7px;
      }
    }
  }

  &.dnr-filter-accordion {
    .mat-expansion-panel {
      box-shadow: none;
    }

    .mat-expansion-panel-body {
      padding: 0;
    }

    .mat-expansion-panel-header {
      .mat-expansion-panel-header-title {
        padding: 0;

        &::before {
          display: none;
        }
      }
    }

    .mat-expansion-panel-header-description {
      padding: 0;
      display: block;

      &::before {
        display: none;
      }
    }
  }
}

//THESE ARE HERE BECAUSE INNERHTML + Encapsulation - Need solution so they're not in here and the static page doesnt bleed into global space
.static-template-card {
  h1,
  h2 {
    position: relative;
    font-family: $fontFamilyHeadings;
    font-size: 1.75rem;
    font-weight: bold;
    color: $red;
    margin: 0 0 20px;

    &::after {
      content: '';
      display: block;
      margin-top: 10px;
      height: 1px;
      width: 100%;
      background: linear-gradient(to right, $red 0%, rgba($red, 0.05) 100%);
    }

    @media screen and (max-width: $break-medium) {
      font-size: 1.5rem;

      &::after {
        background: linear-gradient(to right, rgba($red, 0.05) 0%, $red 50%, rgba($red, 0.05) 100%);
      }
    }
  }

  h3,
  h4,
  h5,
  h6 {
    font-family: $fontFamilyRegular;
    color: #707070;
    font-weight: 500;
  }
}

.static-template-card-button {
  &.make-center {
    p {
      text-align: center;
    }

    a {
      min-width: 50%;
      text-align: center;
    }

    &.third {
      a {
        min-width: 32%;
      }
    }
  }

  p {
    margin: 0;
  }

  a {
    margin-top: 20px;
    background: transparent linear-gradient(180deg, #fcef51, #efe24c) 0 0 no-repeat padding-box;
    box-shadow: 0 3px 10px #fcef5199;
    border: 1px solid #e9db29;
    border-radius: 3px;
    opacity: 1;
    padding: 10px 30px;
    display: inline-block;
    width: auto;
    font-size: 1rem;
    color: #000000;
    font-weight: 700;

    &:hover {
      background: transparent linear-gradient(180deg, #efe24c, #fcef51) 0 0 no-repeat padding-box;
    }

    @media screen and (max-width: $break-medium) {
      display: block;
      text-align: center;
    }
  }
}

.static-template-card {
  p {
    font-size: 1rem;
  }
}

.video-container {
  p {
    margin-top: 40px;
    text-align: center;
    color: #3a3a3a;
  }
}

.text-center {
  text-align: center;

  p {
    text-align: center;
  }
}

.static-template-jumbotron {
  .jumbotron-image-title {
    p {
      margin-bottom: 0;
    }
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Barlow', 'Helvetica Neue', sans-serif;
}

.cdk-overlay-container {
  z-index: 9999;
}

// Style for Toats Alerts // To-Do:: Move to appropriate place
.colored-toast.swal2-icon-success {
  background-color: #a5dc86 !important;
}

.colored-toast.swal2-icon-error {
  background-color: #f27474 !important;
}

.colored-toast.swal2-icon-warning {
  background-color: #f8bb86 !important;
}

.colored-toast.swal2-icon-info {
  background-color: #3fc3ee !important;
}

.colored-toast.swal2-icon-question {
  background-color: #87adbd !important;
}

.colored-toast .swal2-title {
  color: white;
}

.colored-toast .swal2-close {
  color: white;
}

.colored-toast .swal2-html-container {
  color: white;
}

.styled-input-group {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  justify-content: flex-end;

  .styled-input {
    z-index: 1;
    width: 100%;

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .styled-input-group-btn {
    z-index: 2;
    font: normal normal bold 16px/7px $fontFamilyRegular;
    background: transparent linear-gradient(180deg, #f2f2f2 0%, #e8e8e8 100%) 0 0 no-repeat padding-box;
    box-shadow: 0 3px 10px rgba(191, 191, 191, 0.4);
    border-radius: 0 3px 3px 0;
    border: 1px solid #707070;
    letter-spacing: 0;
    color: #000000;
    margin-left: -5px;
    padding: 10px 25px;
  }
}

.styled-select {
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 10px #00000012;
  border: 1px solid #707070;
  border-radius: 3px;

  font: normal normal bold 16px/20px $fontFamilyRegular;

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border: 0;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mdc-floating-label--float-above {
    top: 35px;
  }

  &.d-block {
    width: 100%;
    margin: 5px auto 10px;
  }
}

// Auto Complete
.mat-mdc-autocomplete-panel {
  background: #fefefe;

  p,
  a {
    padding-left: 16px;
    padding-right: 16px;

    &.fallback-error {
      color: #bc2224;
    }

    &.results-link {
      color: #2f83c1;
      text-decoration: underline;
    }
  }

  .mat-mdc-optgroup {
    border-bottom: 1px solid #c4c4c4;
  }

  .mat-mdc-optgroup-label {
    span {
      color: #3a3a3a;
      font-weight: bold;
    }
  }

  .mat-mdc-option {
    transition: all 0.2s ease;
    background: #fefefe;
    min-height: auto;
    padding: 5px 16px !important;

    span {
      color: #707070;
    }

    span.badge {
      font-size: 0.625rem;
      padding: 5px 10px;
      background-color: #f2f2f2 !important;
    }

    &:hover {
      background: #fefefe;

      span {
        font-weight: bold;
        color: #3a3a3a;
      }
    }
  }
}

.link-wrap {
  a {
    white-space: pre; /* CSS 2.0 */
    white-space: pre-wrap; /* CSS 2.1 */
    white-space: pre-line; /* CSS 3.0 */
    white-space: -o-pre-wrap; /* Opera 7 */
    white-space: -moz-pre-wrap; /* Mozilla */
    word-wrap: break-word; /* IE 5+ */
  }
}

.swal-confirm-dialog {
  background: #ffffff 0 0 no-repeat padding-box;
  border-radius: 3px;

  .swal2-title {
    font: normal normal bold 24px/32px $fontFamilyRegular;
    letter-spacing: 0;
    color: #3a3a3a;
  }

  .swal2-html-container {
    font: normal normal normal 16px/24px $fontFamilyRegular;
    color: #707070;
    margin: 1em auto;
  }

  .swal2-actions {
    width: 95%;
    padding-bottom: 20px;

    .scd-btn {
      transition: all 0.3s ease;
      padding: 15px;
      width: 40%;
      margin: 0 auto;
    }

    .scd-delete {
      font: normal normal bold 16px/16px $fontFamilyRegular;
      color: #ffffff;
      background: transparent linear-gradient(180deg, #bc2224 0%, #ae1f21 100%) 0 0 no-repeat padding-box;
      box-shadow: 0 3px 10px #bc22244d;
      border: 1px solid #9a0d11;
      border-radius: 3px;

      &:hover {
        background: transparent linear-gradient(180deg, #ae1f21 0%, #bc2224 100%) 0 0 no-repeat padding-box;
      }
    }

    .scd-cancel {
      font: normal normal bold 16px/16px $fontFamilyRegular;
      color: #0a85c7;
      background: #ffffff 0 0 no-repeat padding-box;
      border: 1px solid #0a85c7;
      border-radius: 3px;

      &:hover {
        color: #ffffff;
        background: #0a85c7;
      }
    }
  }
}

.lightbox {
  top: 50vh !important;
  transform: translateY(-50%);
  position: fixed;
}

.debug {
  background: #f00;
  border: solid 1px black !important;
}

.cky-btn-revisit-wrapper {
  display: none !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

.gooey-bottom-white {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -10px;
    width: 100%;
    height: 30px;
    background-image: url(https://images.ctfassets.net/huwbudysxepr/55Y2VGupU10wXoBUnt9RmY/bd2d292abbc02a751059dec86c854636/Herotear_White.svg);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
  }
}

.section-tear {
  position: relative;

  &.tear-top {
    padding-top: 25px;
    margin-top: 25px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 30px;
      background-image: url(https://images.ctfassets.net/huwbudysxepr/55Y2VGupU10wXoBUnt9RmY/bd2d292abbc02a751059dec86c854636/Herotear_White.svg);
      background-repeat: no-repeat;
      background-position: 0 -7px;
      background-size: cover;
      z-index: 2;
      transform: rotate(180deg);

      @media screen and (max-width: $break-medium) {
        background-position: 0 15px;
      }
    }
  }

  &.tear-bottom {
    padding-bottom: 25px;
    margin-bottom: 25px;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 30px;
      background-image: url(https://images.ctfassets.net/huwbudysxepr/55Y2VGupU10wXoBUnt9RmY/bd2d292abbc02a751059dec86c854636/Herotear_White.svg);
      background-repeat: no-repeat;
      background-position: 0 -7px;
      background-size: cover;
      z-index: 2;

      @media screen and (max-width: $break-medium) {
        background-position: 0 15px;
      }
    }
  }
}
