:root {
  /* Breakpoints */
  --break-xx-large: 1400px;
  --break-x-large: 1200px;
  --break-large: 1024px;
  --break-medium: 994px;
  --break-medium-5: 768px;
  --break-small: 640px;
  --break-xsmall: 420px;

  /* Color Variables */
  --blue: #0a85c7;
  --blue-prime: #5ac7da;
  --blue-sec: #5ac7da;
  --blue-light: #e6f7f9;
  --red-prime: #d32027;
  --red-sec: #9a0d11;
  --red-light: #f8dedf;
  --yellow-prime: #fde700;
  --yellow-sec: #d6ce34;
  --green-prime: #00aa23;
  --green-sec: #00881c;
  --green-light: #d9f2de;

  --grey-scale-0: #3a3a3a;
  --grey-scale-1: #707070;
  --grey-scale-2: #bfbfbf;
  --grey-scale-3: #e8e8e8;
  --grey-scale-4: #f2f2f2;
  --grey-scale-5: #f7f7f7;

  --color-black: #000;
  --color-white: #fff;

  --colors-background-default: #00aa23;

  --booking-com-blue: #003b95;
  --reviews-blue: #135374;
  --review-blue-sec: #3980bc;
  --review-blue-dark: #275dc5;

  /* Font Styles */
  --font-family-headings: 'Barlow', Arial, Helvetica, sans-serif;
  --font-family-regular: 'Barlow', Arial, Helvetica, sans-serif;

  /* Form Styles */
  --input-border-radius: 10px;
  --button-border-radius: 10px;

  $button-colors: (
    blue: (
      normal: (
        color: var(--color-white),
        border: 1px solid #1897af,
        background: linear-gradient(180deg, var(--blue-prime) 0%, #4bbed2 100%),
        box-shadow: 0px 0px 10px 0px rgba(90, 199, 218, 0.2),
      ),
      hover: (
        border: 1px solid #1897af,
        background: linear-gradient(180deg, #4395a3 0%, var(--blue-prime) 100%),
        box-shadow: 0px 0px 10px 0px rgba(90, 199, 218, 0.2),
      ),
      outline: (
        color: var(--blue-prime),
        border: 1px solid var(--blue-prime),
        background: rgba(90, 199, 218, 0),
        box-shadow: 0px 0px 10px 0px rgba(90, 199, 218, 0.2),
      ),
      outline-hover: (
        color: var(--blue-prime),
        border: 1px solid var(--blue-prime),
        background: var(--blue-light),
        box-shadow: 0px 0px 10px 0px rgba(90, 199, 218, 0.2),
      ),
    ),
    green: (
      normal: (
        color: var(--color-white),
        border: 1px solid #00891e,
        background: linear-gradient(180deg, var(--green-prime) 0%, #00a422 100%),
        box-shadow: 0px 0px 10px 0px rgba(0, 170, 35, 0.2),
      ),
      hover: (
        border: 1px solid #00891e,
        background: linear-gradient(180deg, var(--green-sec) 0%, var(--green-prime) 100%),
        box-shadow: 0px 0px 10px 0px rgba(0, 170, 35, 0.2),
      ),
    ),
    yellow: (
      normal: (
        color: var(--grey-scale-0),
        border: 1px solid var(--yellow-sec),
        background: linear-gradient(180deg, #ffee38 0%, var(--yellow-prime) 100%),
        box-shadow: 0px 0px 10px 0px rgba(253, 231, 0, 0.2),
      ),
      hover: (
        border: 1px solid var(--yellow-sec),
        background: linear-gradient(180deg, var(--yellow-sec) 0%, #ffee38 100%),
        box-shadow: 0px 0px 10px 0px rgba(253, 231, 0, 0.2),
      ),
      outline: (
        color: var(--yellow-prime),
        border: 1px solid var(--yellow-prime),
        background: rgba(0, 0, 0, 0.5),
        box-shadow: 0,
      ),
      outline-hover: (
        color: var(--grey-scale-0),
        border: 1px solid var(--yellow-sec),
        background: linear-gradient(180deg, #ffee38 0%, var(--yellow-prime) 100%),
        box-shadow: 0,
      ),
    ),
    red: (
      normal: (
        color: var(--color-white),
        border: 1px solid #93161b,
        background: linear-gradient(180deg, var(--red-prime) 0%, #bd1d23 100%),
        box-shadow: 0px 0px 10px 0px rgba(211, 32, 39, 0.2),
      ),
      hover: (
        border: 1px solid #93161b,
        background: linear-gradient(180deg, var(--red-sec) 0%, var(--red-prime) 100%),
        box-shadow: 0px 0px 10px 0px rgba(211, 32, 39, 0.2),
      ),
      outline: (
        color: var(--red-prime),
        border: 1px solid var(--red-prime),
        background: rgba(211, 32, 39, 0),
        box-shadow: 0px 0px 10px 0px rgba(211, 32, 39, 0.2),
      ),
      outline-hover: (
        color: var(--color-white),
        border: 1px solid #93161b,
        background: var(--red-prime),
        box-shadow: 0px 0px 10px 0px rgba(211, 32, 39, 0.2),
      ),
    ),
    grey: (
      normal: (
        color: var(--grey-scale-0),
        border: 1px solid var(--grey-scale-1),
        background: linear-gradient(180deg, var(--grey-scale-4) 0%, var(--grey-scale-3) 100%),
        box-shadow: 0px 0px 10px 0px rgba(191, 191, 191, 0.3),
      ),
      hover: (
        border: 1px solid var(--grey-scale-1),
        background: linear-gradient(180deg, var(--grey-scale-3) 0%, var(--grey-scale-3) 100%),
        box-shadow: 0px 0px 10px 0px rgba(191, 191, 191, 0.3),
      ),
    ),
  );

  @mixin button-styles($color, $state: normal) {
    $styles: map-get($button-colors, $color);
    $style: map-get($styles, $state);

    transition: all 0.2s ease;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: center;
    opacity: 1;
    line-height: 15px;
    font-weight: 700;
    text-decoration: none;
    font-family: var(--font-family-regular), sans-serif;
    color: map-get($style, color);
    padding: 15px 20px;
    border-radius: var(--button-border-radius);
    background: map-get($style, background);
    border: map-get($style, border);
    box-shadow: map-get($style, box-shadow);

    @if map-has-key($styles, hover) {
      &:hover {
        color: map-get(map-get($styles, hover), color);
        background: map-get(map-get($styles, hover), background);
        border: map-get(map-get($styles, hover), border);
        box-shadow: map-get(map-get($styles, hover), box-shadow);
      }
    }

    @if map-has-key($styles, outline) {
      &.button-outline {
        color: map-get(map-get($styles, outline), color);
        background: map-get(map-get($styles, outline), background);
        border: map-get(map-get($styles, outline), border);
        box-shadow: map-get(map-get($styles, outline), box-shadow);

        @if map-has-key($styles, outline-hover) {
          &:hover {
            color: map-get(map-get($styles, outline-hover), color);
            background: map-get(map-get($styles, outline-hover), background);
            border: map-get(map-get($styles, outline-hover), border);
            box-shadow: map-get(map-get($styles, outline-hover), box-shadow);
          }
        }
      }
    }
    &.disabled,
    &.button-disabled {
      color: var(--grey-scale-0);
      border-color: var(--grey-scale-1);
      background: var(--grey-scale-2);
      cursor: not-allowed;
      opacity: 0.8;
    }
    &.w-unset {
      display: inline-block;
      width: unset;
    }
  }

  @each $color, $styles in $button-colors {
    .button-#{'#{$color}'} {
      @include button-styles($color);
    }
  }
}
